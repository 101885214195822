import { useState } from 'react';
import type { FC, FormEvent } from 'react';
import axios from 'axios';
import {
  makeStyles,
  tokens,
  Field,
  Input,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  Button
} from '@fluentui/react-components';
import { DismissRegular, AddCircleFilled } from '@fluentui/react-icons';

const useStyles = makeStyles({
  root: {
    display: "grid",
    gap: "10px",
    marginBottom: "15px",
    textAlign: "left",
    fontWeight: "bold",
  },
  leftAlign: {
    textAlign: "left"
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  buttonRed: {
    color: 'white',
    backgroundColor: tokens.colorStatusDangerBackground3,
    ':hover': {
      color: 'white',
      backgroundColor: tokens.colorStatusDangerBackground3Hover
    },
  },
  fullWidth: {
    width: '100%'
  },
  errorMessage: {
    color: tokens.colorStatusDangerForeground3,
    fontSize: '13px',
    marginTop: '-15px'
  }
})

const deleteProjectIdFromDb = async (projectId: string) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_MILL_TESTS_API_URL}/mill_tests/exceptions/${projectId}`);
    const data = response.data;
    console.log(data);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

const addProjectIdToDb = async (projectId: string): Promise<boolean> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_MILL_TESTS_API_URL}/mill_tests/exceptions`,
      {projectId}
    );
    const data = response.data;
    console.log(data);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const MillTestProjectExceptionForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState("");
  const [projectId, setProjectId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const styles = useStyles();

  const addProjectId = async (projectId: string) => {
    console.log('ADDING PROJECT', projectId);
    const success = await addProjectIdToDb(projectId);
    if (success) {
      setProject(projectId);
    }
  };

  const removeProjectId = async (projectId: string) => {
    console.log('REMOVING PROJECT', projectId);
    const success = await deleteProjectIdFromDb(projectId);
    if (success) {
      setProject("");
    }
  };

  const handleClear = () => {
    setProjectId("");
    setErrorMessage("");
  };

  const handleSubmit = async (event?: FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    const id = projectId;
    handleClear();

    addProjectId(id);

    setLoading(false);
  };

  const renderMessageBar = () => {
    return (
      <MessageBar layout="multiline">
        <MessageBarBody>
          Le project avec ID <strong>{project}</strong> a été marqué comme une exception
        </MessageBarBody>
        <MessageBarActions
          containerAction={
            <Button
              aria-label="dismiss"
              appearance="transparent"
              onClick={() => setProject("")}
              icon={<DismissRegular />}
            />
          }
        >
          <Button className={styles.buttonRed} onClick={() => removeProjectId(project)}>Annuler</Button>
        </MessageBarActions>
      </MessageBar>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Field label="Exceptions" id="exceptions-id" className={styles.root}>
          <Input
            autoComplete="off"
            placeholder="Ajouter par Project ID"
            value={projectId}
            onChange={(event) => setProjectId(event.target.value)}
          />
        </Field>
        {errorMessage && (
          <div className={styles.errorMessage}>
            {errorMessage}
          </div>
        )}
        <div className={styles.buttonGroup}>
          <Button disabled={!projectId || loading} appearance='primary' type='submit' icon={<AddCircleFilled />}>
            Ajouter
          </Button>
        </div>
      </form>
      {project && renderMessageBar()}
    </>
  );
}

export default MillTestProjectExceptionForm;
